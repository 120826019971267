import { inject }              from 'aurelia-framework';
import { PLATFORM }            from 'aurelia-pal';
import { BaseFormViewModel }   from 'base-form-view-model';
import { FormSchema }          from 'modules/bituminous-mixtures/ligands/form-schema';
import { BMLigandsRepository } from 'modules/bituminous-mixtures/ligands/services/repository';
import { CommentFormSchema }   from 'modules/core/custom-components/comments/form-schema';
import { Sector }              from 'modules/management/models/sector';
import { AppContainer }        from 'resources/services/app-container';

@inject(AppContainer, BMLigandsRepository, FormSchema, CommentFormSchema)
export class CreateBMLigand extends BaseFormViewModel {

    headerTitle = 'form.title.create-new-record';
    routePrefix = 'bituminous-mixtures.ligands';
    permissionPrefix;

    alert = {};
    model = {};
    tabs  = {};

    create             = true;
    showAdditionalInfo = true;

    constructor(appContainer, repository, formSchema, commentSchema) {
        super(appContainer);

        this.repository    = repository;
        this.formSchema    = formSchema;
        this.commentSchema = commentSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/bituminous-mixtures/ligands/form.html');
    }

    /**
     * Handles canActivate event
     */
    async canActivate() {

        this.sectorId = Sector.BITUMINOUS_MIXTURES_LIGANDS;

        this.permissionPrefix = this.routePrefix;
        this.formId           = 'bituminous-mixtures-ligands-creation-form';

        this.backRoute = `${this.routePrefix}.index`;

        return this.redirectToSelectLotIntervention(`${this.routePrefix}.create`) && super.can([
            this.permissionPrefix + '.manage',
            this.permissionPrefix + '.create',
        ]);
    }

    /**
     * Handles activate event
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async activate(params) {
        super.activate();

        this.initializeModel();

        // Fetch the next number and selected lot information
        const selectedLotInformation = this.appContainer.authenticatedUser.getAllSelectedLotInformation();
        const { next_number }        = await this.repository.nextNumber();

        // Assign base lot information
        this.assignBaseLotInformation(next_number, selectedLotInformation);

                if (this.appContainer.authenticatedUser.isLotClosed) {
            this.alert = this.warningAlertMessage('messages.selected-lot-intervention-is-closed');
        }

        // Get schema for each tab
        this.tabs.selectBulletins       = await this.formSchema.selectBulletinsSchema.schema(this);
        this.tabs.general               = this.formSchema.generalSchema.schema(this);
        this.tabs.general.lotInfoSchema = this.formSchema.lotInfoSchema(this);
        this.tabs.additionalInfo        = this.formSchema.additionalInfoSchema.schema(this);
        this.globalSchema               = this.formSchema.globalSchema(this);
        this.commentSchema              = this.commentSchema.schema(this);
    }

    initializeModel() {
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        this.initialModel.comment = this.commentSchema.model();
        this.model.comment        = this.commentSchema.model();
    }

    /**
     * Assigns base lot information to the model
     *
     * @param nextNumber
     * @param selectedLotInformation
     */
    assignBaseLotInformation(nextNumber, selectedLotInformation) {
        const baseInfo = {
            number:                 nextNumber,
            entity_name:            selectedLotInformation.entity_name,
            entity_id:              selectedLotInformation.entity_id,
            team_name:              selectedLotInformation.team_name,
            team_id:                selectedLotInformation.team_id,
            highway_name:           selectedLotInformation.highway_name,
            highway_id:             selectedLotInformation.highway_id,
            concession_name:        selectedLotInformation.concession_name,
            concession_id:          selectedLotInformation.concession_id,
            intervention_type_name: selectedLotInformation.lot_intervention_type_name,
            intervention_type_id:   selectedLotInformation.lot_intervention_type_id,
            lot_intervention_name:  selectedLotInformation.lot_intervention_name,
            lot_intervention_id:    selectedLotInformation.lot_intervention_id,
        };

        this.initialModel.assign(baseInfo);
        this.model.assign(baseInfo);
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.create(this.model)
            .then((response) => {
                this.handleResponse(response, {
                    name:   this.backRoute,
                    params: this.backRouteParams,
                });
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }
}
