import { EventAggregator }                from 'aurelia-event-aggregator';
import { inject }                         from 'aurelia-framework';
import { I18N }                           from 'aurelia-i18n';
import { BooleanStatus }                  from 'modules/administration/models/boolean-status';
import { BMLigandApplicationsRepository } from 'modules/bituminous-mixtures/ligands/services/bm-ligand-applications';
import { HighwaysRepository }             from 'modules/management/concessions/concessions-tree/highways/services/repository';
import { InterventionTypesRepository }    from 'modules/management/concessions/concessions-tree/intervention-types/services/repository';
import { LotInterventionsRepository }     from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { EntitiesRepository }             from 'modules/management/concessions/entities/services/repository';
import { TeamsRepository }                from 'modules/management/concessions/teams/services/repository';
import { ConcessionsRepository }          from 'modules/management/specifications/concessions/services/repository';
import { BaseFilterFormSchema }           from 'resources/classes/base-filter-form-schema';
import { SessionStorage }                 from 'resources/services/session-storage';

@inject(SessionStorage, EventAggregator, I18N, ConcessionsRepository, EntitiesRepository, TeamsRepository, HighwaysRepository, InterventionTypesRepository, LotInterventionsRepository, BMLigandApplicationsRepository)
export class FilterFormSchema extends BaseFilterFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        created_by:      [],
        created_at_from: null,
        created_at_to:   null,
    };

    /**
     * Constructor
     *
     * @param storage
     * @param eventAggregator
     * @param i18n
     * @param concessionsRepository
     * @param entitiesRepository
     * @param teamsRepository
     * @param highwaysRepository
     * @param interventionTypesRepository
     * @param lotInterventionsRepository
     * @param ligandApplicationsRepository
     */
    constructor(storage, eventAggregator, i18n, concessionsRepository, entitiesRepository, teamsRepository, highwaysRepository, interventionTypesRepository, lotInterventionsRepository, ligandApplicationsRepository) {
        super(storage, eventAggregator, i18n);

        this.concessionsRepository        = concessionsRepository;
        this.entitiesRepository           = entitiesRepository;
        this.teamsRepository              = teamsRepository;
        this.highwaysRepository           = highwaysRepository;
        this.interventionTypesRepository  = interventionTypesRepository;
        this.lotInterventionsRepository   = lotInterventionsRepository;
        this.ligandApplicationsRepository = ligandApplicationsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns
     */
    model(viewModel) {
        return super.filterModel(viewModel, this.modelDefaults);
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param isBuilder
     * @returns {*[]}
     */
    async schema(viewModel, isBuilder = false) {
        this.test_number_from = {
            type:     'number',
            key:      'test_number_from',
            label:    'form.field.sample-number(from)',
            size:     3,
            required: false,
        };

        this.test_number_to = {
            type:     'number',
            key:      'test_number_to',
            label:    'form.field.sample-number(to)',
            size:     3,
            required: false,
        };

        this.concession_id = {
            type:         'select2',
            key:          'concession_id',
            label:        'form.field.concession',
            size:         6,
            required:     false,
            remoteSource: () => this.concessionsRepository.allByLoggedUser(),
            observers:    [
                async (newValue, oldValue) => {
                    if (!newValue) {
                        viewModel.filterModel.highway_id           = null;
                        viewModel.filterModel.intervention_type_id = null;
                    }

                    await this.highwaysRepository.lotGroupedAll({
                        concession_id: newValue,
                    }).then((response) => {
                        this.highway_id.options = this.highway_id.instance.transformOptionsObject(response);
                    });

                    await this.lot_intervention_id.instance.fetchData();
                },
            ],
        };

        this.highway_id = {
            type:         'select2',
            key:          'highway_id',
            label:        'form.field.highway-road',
            size:         6,
            required:     false,
            remoteSource: () => this.highwaysRepository.lotGroupedAll({
                concession_id: viewModel.filterModel.concession_id,
            }),
            observers:    [
                (newValue, oldValue) => {
                    this.intervention_type_id.instance.disable(!newValue);

                    if (newValue) {
                        return this.highwaysRepository.interventionTypes(newValue, { filter_by_logged_user: true }).then((response) => {
                            this.intervention_type_id.options = response;
                            this.intervention_type_id.instance.enable(response.length);
                        });
                    } else {
                        this.intervention_type_id.options = [{ id: null, name: this.i18n.tr('form.field.select-first-highway-road'), status_id: BooleanStatus.INACTIVE }];
                    }
                },
            ],
        };

        this.intervention_type_id = {
            type:         'select2',
            key:          'intervention_type_id',
            label:        'form.field.intervention-type',
            size:         6,
            required:     false,
            remoteSource: () => {
                return viewModel.filterModel.highway_id
                    ? this.highwaysRepository.interventionTypes(viewModel.filterModel.highway_id, { filter_by_logged_user: true })
                    : Promise.resolve([{ id: null, name: this.i18n.tr('form.field.select-first-highway-road'), status_id: BooleanStatus.INACTIVE }]);
            },
            observers:    [
                (newValue, oldValue) => {
                    return this.lotInterventionsRepository.lotGroupedAll({
                        concession_id:        viewModel.filterModel.concession_id,
                        highway_id:           viewModel.filterModel.highway_id,
                        intervention_type_id: newValue,
                    }).then((response) => {
                        this.lot_intervention_id.options = this.lot_intervention_id.instance.transformOptionsObject(response);
                    });
                },
            ],
        };

        this.lot_intervention_id = {
            type:         'select2',
            key:          'lot_intervention_id',
            label:        'form.field.lot-intervention',
            size:         6,
            required:     false,
            remoteSource: () => this.lotInterventionsRepository.lotGroupedAll({
                concession_id:        viewModel.filterModel.concession_id,
                highway_id:           viewModel.filterModel.highway_id,
                intervention_type_id: viewModel.filterModel.intervention_type_id,
            }),
        };

        this.entity_id = {
            type:         'select2',
            key:          'entity_id',
            label:        'form.field.entity',
            size:         6,
            remoteSource: () => this.entitiesRepository.all(),
            required:     false,
            attributes:   {
                disabled: false,
            },
            observers:    [
                (newValue) => {
                    this.team_id.instance.disable(!newValue);

                    if (newValue) {
                        return this.entitiesRepository.teams(newValue).then((response) => {
                            this.team_id.options = response;
                            this.team_id.instance.enable(response.length);
                        });
                    }
                },
            ],
        };

        this.team_id = {
            type:         'select2',
            key:          'team_id',
            label:        'form.field.team',
            size:         6,
            remoteSource: () => (viewModel.filterModel.entity_id)
                ? this.entitiesRepository.teams(viewModel.filterModel.entity_id)
                : Promise.resolve([]),
            required:     false,
            attributes:   {
                disabled: !(viewModel.filterModel.entity_id),
            },
        };

        this.external_reference = {
            type:     'text',
            key:      'external_reference',
            label:    'form.field.external-reference',
            size:     6,
            required: false,
        };

        this.ligand_type = {
            type:     'text',
            key:      'ligand_type',
            label:    'form.field.ligand-type',
            size:     4,
            required: false,
        };

        this.commercial_designation = {
            type:     'text',
            key:      'commercial_designation',
            label:    'form.field.commercial-designation',
            size:     4,
            required: false,
        };

        this.central_number = {
            type:     'text',
            key:      'central_number',
            label:    'form.field.central-number',
            size:     4,
            required: false,
        };

        this.central_designation = {
            type:     'text',
            key:      'central_designation',
            label:    'form.field.central-designation',
            size:     4,
            required: false,
        };

        this.supplier = {
            type:     'text',
            key:      'supplier',
            label:    'form.field.supplier',
            size:     4,
            required: false,
        };

        this.ligand_application = {
            type:     'text',
            key:      'ligand_application',
            label:    'form.field.application',
            size:     4,
            required: false,
        };

        this.date_from = {
            type:     'material-ui-date-picker',
            key:      'date_from',
            label:    'form.field.collection-date(from)',
            size:     3,
            required: false,
        };

        this.date_to = {
            type:     'material-ui-date-picker',
            key:      'date_to',
            label:    'form.field.collection-date(to)',
            size:     3,
            required: false,
        };

        this.validation_status = {
            type:     'boolean-options-select',
            key:      'validation_status',
            label:    'form.field.validation-status',
            size:     4,
            required: false,
        };

        this.verification_status = {
            type:     'boolean-options-select',
            key:      'concessionary_status',
            label:    'form.field.concessionary-status',
            size:     4,
            required: false,
        };

        this.inspection_status = {
            type:     'boolean-options-select',
            key:      'inspection_status',
            label:    'form.field.inspection-status',
            size:     4,
            required: false,
        };

        this.created_by = {
            type:     'user-entity-team-lot-multiselect',
            key:      'created_by',
            label:    'form.field.created-by',
            size:     4,
            required: false,
        };

        this.created_at_from = {
            type:     'material-ui-date-picker',
            key:      'created_at_from',
            label:    'form.field.created-at(from)',
            size:     4,
            required: false,
        };

        this.created_at_to = {
            type:     'material-ui-date-picker',
            key:      'created_at_to',
            label:    'form.field.created-at(to)',
            size:     4,
            required: false,
        };

        this.searchButton = {
            type:       'submit',
            label:      'form.button.search',
            action:     () => this.eventAggregator.publish('datatable-must-be-reloaded', {
                listingId: viewModel.listingId,
                criteria:  viewModel.filterModel,
            }),
            attributes: {
                class: 'btn btn-teal filter-submit',
            },
            icon:       {
                attributes: {
                    class: 'icon-search4',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => this.eventAggregator.publish('datatable-filter-must-be-reseted', viewModel.listingId),
            attributes: {
                class: 'btn btn-light filter-reset',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.searchButton,
                this.clearButton,
            ],
        };

        return [
            [this.test_number_from, this.test_number_to, this.concession_id],
            [this.entity_id, this.team_id],
            [this.highway_id, this.intervention_type_id],
            [this.lot_intervention_id, this.ligand_type],
            [this.commercial_designation, this.central_number, this.central_designation],
            [this.supplier, this.ligand_application],
            [this.external_reference, this.date_from, this.date_to],
            [this.validation_status, this.verification_status, this.inspection_status],
            [this.created_by, this.created_at_from, this.created_at_to],
            [this.buttons],
        ];
    }
}
